export const mapRoutes = {
  signIn: {
    pathname: '/sign_in',
    hasPublicAccess: true,
    component: 'SignIn',
    layout: 'AuthLayout',
  },
  signUp: {
    pathname: '/sign_up',
    hasPublicAccess: true,
    component: 'SignUp',
    layout: 'AuthLayout',
  },
  passwordRecover: {
    pathname: '/password_recover',
    hasPublicAccess: true,
    component: 'PasswordRecover',
    layout: 'AuthLayout',
  },
  resetPassword: {
    pathname: '/password_reset',
    hasPublicAccess: true,
    component: 'ResetPassword',
    layout: 'AuthLayout',
  },
  dashboard: {
    pathname: '/dashboard',
    hasPublicAccess: false,
    component: 'Dashboard',
    layout: 'DefaultLayout',
  },
  notFound: {
    pathname: '/404',
    hasPublicAccess: true,
    component: 'NotFound',
    layout: 'EmptyLayout',
  },
  integrations: {
    pathname: '/integrations',
    hasPublicAccess: false,
    component: 'Integrations',
    layout: 'DefaultLayout',
  },
  products: {
    pathname: '/products',
    hasPublicAccess: false,
    component: 'Products',
    layout: 'DefaultLayout',
  },
  employeesForProduct: {
    pathname: 'product/:product/users',
    hasPublicAccess: false,
    component: 'EmployeesForProduct',
    layout: 'DefaultLayout',
  },
  employees: {
    pathname: '/employees',
    hasPublicAccess: false,
    component: 'Employees',
    layout: 'DefaultLayout',
  },
  employeeApps: {
    pathname: 'employee/:employee/products',
    hasPublicAccess: false,
    component: 'EmployeeApps',
    layout: 'DefaultLayout',
  },
  billing: {
    pathname: '/billing',
    hasPublicAccess: false,
    component: 'Billing',
    layout: 'DefaultLayout',
  },
  productDetails: {
    pathname: '/product/:productId/details',
    hasPublicAccess: false,
    component: 'ProductDetails',
    layout: 'DefaultLayout',
  },
  privacyPolicy: {
    pathname: '/privacy-policy',
    hasPublicAccess: true,
    component: 'PrivacyPolicy',
    layout: 'EmptyLayout',
  },
  termsAndConditions: {
    pathname: '/terms-and-conditions',
    hasPublicAccess: true,
    component: 'TermsAndConditions',
    layout: 'EmptyLayout',
  },
  adminEmployees: {
    pathname: '/admin/employees',
    hasPublicAccess: false,
    component: 'EditEmployees',
    layout: 'DefaultLayout',
  },
  adminProducts: {
    pathname: '/admin/products',
    hasPublicAccess: false,
    component: 'ManageProducts',
    layout: 'DefaultLayout',
  },
  adminSavings: {
    pathname: '/admin/savings',
    hasPublicAccess: false,
    component: 'ManageSavings',
    layout: 'DefaultLayout',
  },
  adminContracts: {
    pathname: '/admin/contracts',
    hasPublicAccess: false,
    component: 'CancelContracts',
    layout: 'DefaultLayout',
  },
  adminSingleCosts: {
    pathname: '/admin/single_costs',
    hasPublicAccess: false,
    component: 'CreateSingleCosts',
    layout: 'DefaultLayout',
  },
  adminMassOnboarding: {
    pathname: '/admin/onboarding',
    hasPublicAccess: false,
    component: 'MassOnboarding',
    layout: 'DefaultLayout',
  },
  adminCompaniesAdmins: {
    pathname: '/admin/companies_admins',
    hasPublicAccess: false,
    component: 'CompaniesAdmins',
    layout: 'DefaultLayout',
  },
  landingPage: {
    pathname: '/',
    hasPublicAccess: true,
    component: 'LandingPage',
    layout: 'EmptyLayout',
  },
  // claraLandingPage: {
  //   pathname: '/clara',
  //   hasPublicAccess: true,
  //   component: 'ClaraLandingPage',
  //   layout: 'EmptyLayout',
  // },
  // potentialSavingsCalculator: {
  //   pathname: '/savings-calculator',
  //   hasPublicAccess: true,
  //   component: 'PotentialSavingsCalculator',
  //   layout: 'AuthLayout',
  // },
  savings: {
    pathname: '/savings',
    hasPublicAccess: false,
    component: 'Savings',
    layout: 'DefaultLayout',
  },
  requestForProposal: {
    pathname: '/request-for-proposal',
    hasPublicAccess: false,
    component: 'RequestForProposal',
    layout: 'DefaultLayout',
  },
  requestDetails: {
    pathname: '/request-for-proposal/:requestId/details',
    hasPublicAccess: false,
    component: 'RequestDetails',
    layout: 'DefaultLayout',
  },
  requestHistory: {
    pathname: '/request-for-proposal/:requestId/history',
    hasPublicAccess: false,
    component: 'RequestHistory',
    layout: 'DefaultLayout',
  },
  demo: {
    pathname: '/demo',
    hasPublicAccess: true,
    component: 'Demo',
    layout: 'EmptyLayout',
  },
  departmentsCosts: {
    pathname: '/department-costs-report',
    hasPublicAccess: true,
    component: 'DepartmentCosts',
    layout: 'DefaultLayout',
  },
  cases: {
    pathname: '/cases/:company',
    hasPublicAccess: true,
    component: 'Cases',
    layout: 'EmptyLayout',
  },
  manageSoftwares: {
    pathname: '/admin/softwares',
    hasPublicAccess: false,
    component: 'ManageSoftwares',
    layout: 'DefaultLayout',
  },
  settings: {
    pathname: '/settings',
    hasPublicAccess: false,
    component: 'Settings',
    layout: 'DefaultLayout',
  },
  settingsProfile: {
    pathname: '/settings/profile/:profileId',
    hasPublicAccess: false,
    component: 'SettingsProfile',
    layout: 'DefaultLayout',
  },
  instances: {
    pathname: '/instances',
    hasPublicAccess: false,
    component: 'Instances',
    layout: 'DefaultLayout',
  },
  instanceDetails: {
    pathname: '/instances/:instanceId/details',
    hasPublicAccess: false,
    component: 'InstanceDetails',
    layout: 'DefaultLayout',
  },
  integrationsLp: {
    pathname: '/integracoes',
    hasPublicAccess: true,
    component: 'IntegrationsLp',
    layout: 'EmptyLayout',
  },
}
